import {Locale} from '@/types/quasar/Locale'

// eslint-disable-next-line
export function useDateUtils() {
  
  const frLocale: Locale = {
    days: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    daysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juill._août_sept._oct._nov._déc.'.split('_'),
    firstDayOfWeek: 0
  }

  /**
   * Takes a string in the format `YYYY/MM/DD` and returns a timestamp in seconds.
   * @param strDate The string date to parse.
   * @returns The number of seconds since `epoch`.
   */
  function parseDateToTimestamp(strDate: string): number | undefined {
    if (!strDate || strDate.length !== 10)
      return undefined
    
    const year = parseInt(strDate.substring(0, 4))
    const month = parseInt(strDate.substring(5, 7)) - 1
    const day = parseInt(strDate.substring(8, 10))
    
    if (year < 1900 || year > 2100)
      return undefined
    
    if (month < 0 || month > 11)
      return undefined
    
    if (day < 1 || day > 31)
      return undefined
    
    return Math.floor(Date.UTC(year, month, day) / 1000)
  }
  
  function timestampToString(
    timestamp: number | undefined,
    lang: string,
    includeTime = false,
    forceUtc = false
  ): string {
    if (!timestamp)
      return ''

    const date = timestamp ? new Date(timestamp * 1000) : undefined
    
    if (!date)
      return ''

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
    
    if (includeTime) {
      options.hour = 'numeric'
      options.minute = 'numeric'
      options.hour12 = false
    }
    
    if (forceUtc)
      options.timeZone = 'UTC'
    
    const strDate = date
      .toLocaleDateString('fr-CA', options)
      .replaceAll('-', '/')
    
    return lang === 'fr'
      ? strDate.replace(' h ', 'h')
      : strDate.replace(' h ', ':')
  }

  function timestampToLongString(
    timestamp: number | undefined,
    lang: string,
    forceUtc = false
  ): string {
    if (!timestamp)
      return ''

    const date = timestamp ? new Date(timestamp * 1000) : undefined

    if (!date)
      return ''

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }

    if (forceUtc)
      options.timeZone = 'UTC'

    return date.toLocaleDateString(lang, options)
  }
  
  return {
    frLocale,
    parseDateToTimestamp,
    timestampToString,
    timestampToLongString
  }
}