import {EntityWithTmdAccountStateBase} from '@/store/types/EntityWithTmdAccountStateBase'
import {IOrganizationDto} from '@/types/dtos/IOrganizationDto'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {Message} from '@/types/Message'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {IApiResponse} from '@/types/responses/IApiResponse'
import {ITmdAccountDto} from '@/types/dtos/ITmdAccountDto'
import {IServerError} from '@/types/axios/IServerError'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {IFileUploadResponse} from '@/types/responses/IFileUploadResponse'
import {useUuidUtils} from '@/composition/useUuidUtils'
import {IGetOrganizationForUrlResponse} from '@/types/responses/IGetOrganizationForUrlResponse'
import {ITmdOrganizationClientsFolderDto} from '@/types/dtos/ITmdOrganizationClientsFolderDto'

export class OrganizationsState extends EntityWithTmdAccountStateBase<IOrganizationDto> {
  authenticatedUserOrganizationName: string
  subscriptionIsValid: boolean
  tmdClientsFolder: ITmdOrganizationClientsFolderDto | undefined
  
  constructor(messages: Message[]) {
    super(messages, 'organizations')
    this.authenticatedUserOrganizationName = ''
    this.subscriptionIsValid = false
    this.tmdClientsFolder = undefined
  }

  async fetchAndSetCurrentForUrl(formUrl: string): Promise<boolean> {
    try {
      const response = await axios.get<IGetOrganizationForUrlResponse>(`${this.serviceRoot}/for-url/${formUrl}`)
      this.current = response.data.organization
      this.subscriptionIsValid = response.data.subscriptionIsValid
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      this.subscriptionIsValid = false

      return false
    }
  }

  async isFormUrlAvailable(formUrl: string, organizationId: string): Promise<boolean> {
    if (!formUrl || formUrl.length < 3)
      return false

    try {
      const response = await axios.get<boolean>(
        this.serviceRoot + '/is-form-url-available', {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
          params: {
            organizationId: organizationId,
            formUrl: formUrl
          }
        })

      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async fetchAuthenticatedUserOrganizationName(id: string): Promise<boolean> {
    if (!id || id.length == 0 || id == useUuidUtils().EmptyUuid) {
      this.authenticatedUserOrganizationName = ''
      return false
    }
    
    try {
      const response = await axios.get<string>(
        this.serviceRoot + '/' + id + '/name', {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      this.authenticatedUserOrganizationName = response.data
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async fetchTmdClientsFolder(): Promise<boolean> {
    if (this.current === undefined)
      return false
    
    try {
      const response = await axios.get<ITmdOrganizationClientsFolderDto>(
        this.serviceRoot + '/' + this.current.id + '/tmd-clients-folder', {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      this.tmdClientsFolder = response.data
      return true
    }
    catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async updateAndSetTmdClientsFolder(folderUrl: string) : Promise<boolean> {
    if (this.current === undefined)
      return false

    try {
      const response = await axios.patch<unknown, AxiosResponse<ITmdOrganizationClientsFolderDto>>(
        this.serviceRoot + '/' + this.current.id + '/tmd-clients-folder',
        {
          folderUrl: folderUrl
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.tmdClientsFolder = response.data
      return true
    }
    catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async createTmdAccount(): Promise<boolean> {
    if (this.current === undefined)
      return false

    try {
      const response = await axios.post<unknown, AxiosResponse<ITmdAccountDto>>(
        this.serviceRoot + '/' + this.current.id + '/create-tmd-account',
        {},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.current.tmdAccount = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async linkTmdAccount(userId: string, email: string, password: string): Promise<boolean> {
    if (this.current === undefined)
      return false

    try {
      const response = await axios.post<unknown, AxiosResponse<ITmdAccountDto>>(
        this.serviceRoot + '/' + this.current.id + '/link-tmd-account',
        {
          userId: userId,
          email: email,
          password: password
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.current.tmdAccount = response.data
      this.messages.push(new Message(Message.SUCCESS, 'Le compte Tag My Doc a bien été lié à l\'organisation.'))

      return true
    } catch (e) {

      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError<IServerError>

        if (axiosError.response && axiosError.response.status === 401) {
          this.messages.push(new Message(Message.ERROR, 'L\'authentification a échoué. Veuillez réessayer.'))
          return false
        }
      }

      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async createAndAddUser(userName: string, role: string, lastName: string, firstName: string) : Promise<boolean> {
    if (this.current === undefined)
      return false

    const axiosUtils = useAxiosUtils()

    try {
      const response = await axios.post<unknown, AxiosResponse<IApiResponse>>(
        this.serviceRoot + '/create-user-and-add-to-organization',
        {
          organizationId: this.current.id,
          userName: userName,
          role: role,
          lastName: lastName,
          firstName: firstName
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.messages.push(axiosUtils.responseToMessage(response))
      return true
    } catch (e) {
      this.messages.push(axiosUtils.errorToMessage(e))
      return false
    }
  }

  async uploadLogo(organizationId: string, file: File, t: (key: string) => string): Promise<string | undefined> {
    if (!organizationId || organizationId === useUuidUtils().EmptyUuid)
      return undefined

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await axios.post<FormData, AxiosResponse<IFileUploadResponse>>(
        this.serviceRoot + '/' + organizationId + '/upload-logo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${useJwtUtils().getAccessToken()}`
          }
        })

      return response.data.fileName
    } catch (e) {
      if (useAxiosUtils().getErrorCode(e) == 413)
        this.messages.push(new Message(Message.ERROR, t('error.fileTooLarge'))) // TEST THIS! .........................
      else
        this.messages.push(useAxiosUtils().errorToMessage(e))

      return undefined
    }
  }
  
  async resetConvofloPermissions(organizationId: string, taxYear: number): Promise<boolean> {
    if (!organizationId || organizationId === useUuidUtils().EmptyUuid)
      return false

    try {
      await axios.post<unknown, AxiosResponse<IApiResponse>>(
        `${this.serviceRoot}/${organizationId}/rewrite-convoflow-permissions/${taxYear}`,
        {},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}
