import {EntityWithTmdAccountStateBase} from "@/store/types/EntityWithTmdAccountStateBase";
import {IUserDto} from "@/types/dtos/IUserDto";
import {Message} from "@/types/Message";
import axios, {AxiosError, AxiosResponse} from 'axios'
import {ITmdAccountDto} from '@/types/dtos/ITmdAccountDto'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {IServerError} from '@/types/axios/IServerError'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {useUuidUtils} from '@/composition/useUuidUtils'
import {IUserNoteDto} from '@/types/dtos/IUserNoteDto'

export class UsersState extends EntityWithTmdAccountStateBase<IUserDto> {
  constructor(messages: Message[]) {
    super(messages, 'users');
  }
  
  async updatePreferredLanguage(userId: string, language: string): Promise<boolean> {
    if (!userId || userId === useUuidUtils().EmptyUuid)
      return false
    
    try {
      await axios.patch<unknown, AxiosResponse<IUserDto>>(
        this.serviceRoot + '/' + userId + '/preferred-language',
        {
          language: language
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async logInToTmd(email: string, password: string): Promise<boolean> {
    if (this.current === undefined)
      return false

    try {
      const response = await axios.post<unknown, AxiosResponse<ITmdAccountDto>>(
        this.serviceRoot + '/' + this.current.id + '/log-in-to-tmd',
        {
          email: email,
          password: password
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.current.tmdAccount = response.data
      this.messages.push(new Message(Message.SUCCESS, 'Le compte Tag My Doc a bien été lié à l\'organisation.'))

      return true
    } catch (e) {

      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError<IServerError>

        if (axiosError.response && axiosError.response.status === 401) {
          this.messages.push(new Message(Message.ERROR, 'L\'authentification a échoué. Veuillez réessayer.'))
          return false
        }
      }

      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async deleteUser(userId: string): Promise<boolean> {
    try {
      await axios.delete(
        `${this.serviceRoot}/${userId}`,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }
  }
  
  async removePartner(): Promise<boolean> {
    try {
      await axios.post(
        `${this.serviceRoot}/remove-partner`,
        {},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async getNote(userId: string): Promise<IUserNoteDto | undefined> {
    try {
      const response = await axios.get<IUserNoteDto | undefined>(
        `${this.serviceRoot}/${userId}/note`,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})
      
      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return undefined
    }
  }
  
  async saveNote(userId: string, userNoteDto: IUserNoteDto): Promise<boolean> {
    try {
      await axios.patch(
        `${this.serviceRoot}/${userId}/note`,
        {
          clientNumber: userNoteDto.clientNumber,
          note: userNoteDto.note},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}
