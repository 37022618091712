import {EntityStateBase} from '@/store/types/EntityStateBase'
import {IFilledFormDocumentListDto} from '@/types/dtos/filledForm/IFilledFormDocumentListDto'
import {Message} from '@/types/Message'
import {useUuidUtils} from '@/composition/useUuidUtils'
import axios from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {IFilledFormDocumentDto} from '@/types/dtos/filledForm/IFilledFormDocumentDto'

export class FilledFormDocumentListsState extends EntityStateBase<IFilledFormDocumentListDto>  {
  documentLists: Map<string, IFilledFormDocumentListDto>
  
  constructor(messages: Message[]) {
    super(messages, 'filled-form-document-lists')
    this.documentLists = new Map<string, IFilledFormDocumentListDto>()
  }
  
  postFetch(): Promise<boolean> {
    return Promise.resolve(false);
  }
  
  async fetchForFilledForm(filledFormId: string): Promise<IFilledFormDocumentListDto | undefined> {
    if (!filledFormId || filledFormId.length < 0 || filledFormId === useUuidUtils().EmptyUuid)
      return undefined

    try {
      const response = await axios.get<IFilledFormDocumentListDto>(
        this.serviceRoot + '/get-for-filled-form/' + filledFormId, {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      if (response.data && response.data.id !== useUuidUtils().EmptyUuid)
        this.documentLists.set(response.data.id, response.data)
      
      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return undefined
    }
  }
  
  updateDocumentSubmitState(listId: string, document: IFilledFormDocumentDto): void {
    if (!this.documentLists.has(listId))
      return
    
    const list = this.documentLists.get(listId) as IFilledFormDocumentListDto
    const documentIndex = document.childId
      ? list.filledFormDocuments.findIndex(d => d.questionId === document.questionId && d.childId === document.childId)
      : list.filledFormDocuments.findIndex(d => d.questionId === document.questionId)
    
    if (documentIndex < 0)
      return
    
    list.filledFormDocuments[documentIndex].isSubmitted = document.isSubmitted
  }
}