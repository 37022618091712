import {createI18n, LocaleMessages} from 'vue-i18n'
import Cookies from 'universal-cookie'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<Record<string, string>> {
  const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<Record<string, string>> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function getDefaultLocale(): string {
  // First, check if a cookie exists
  const language = new Cookies().get<string>('language')

  if (language != undefined && language.length > 0)
    return language.toLowerCase().startsWith('en') ? 'en' : 'fr'

  return process.env.VUE_APP_I18N_LOCALE || 'fr'
}

export default createI18n({
  legacy: false,
  locale: getDefaultLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
