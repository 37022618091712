import {IEntityDto} from "@/types/dtos/IEntityDto";

export interface IPhoneNumberDto extends IEntityDto {
  label: string
  type: string
  number: string
  extension: string
  isPublic: boolean
}

export const EmptyPhoneNumber: IPhoneNumberDto = {
  id: '00000000-0000-0000-0000-000000000000',
  label: '',
  type: '',
  number: '',
  extension: '',
  isPublic: false
}