import {EntityStateBase} from '@/store/types/EntityStateBase'
import {Message} from '@/types/Message'
import {IFilledFormAssignmentDto} from '@/types/dtos/filledForm/IFilledFormAssignmentDto'
import axios, {AxiosResponse} from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'

export class FilledFormAssignmentsState extends EntityStateBase<IFilledFormAssignmentDto> {
  progressValues: string[]
  
  constructor(messages: Message[]) {
    super(messages, 'filled-form-assignments')
    this.progressValues = ['Assigned', 'OnHold', 'Ongoing', 'ToBeReviewed', 'Complete', 'Sent']
  }

  postFetch(): Promise<boolean> {
    return Promise.resolve(false)
  }

  async createOrUpdate(assignment: IFilledFormAssignmentDto): Promise<IFilledFormAssignmentDto | undefined> {
    try {
      const response = await axios.put<IFilledFormAssignmentDto, AxiosResponse<IFilledFormAssignmentDto>>(
        this.serviceRoot,
        assignment,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return undefined
    }
  }
}