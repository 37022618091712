import Cookies from 'universal-cookie'
import jwtDecode, {JwtPayload} from 'jwt-decode'

// eslint-disable-next-line
export function useJwtUtils() {
  function getAccessToken(): string {
    return new Cookies().get<string>('accessToken')
  }

  function accessTokenIsValid(): boolean {
    return getAccessTokenExpirationInSeconds() > 0
  }
  
  function getAccessTokenExpirationInSeconds(): number {
    const accessToken = new Cookies().get<string>('accessToken')

    if (accessToken == undefined || accessToken === 'undefined' || accessToken.length == 0)
      return -1
    const jwtTokenExp = jwtDecode<JwtPayload>(accessToken).exp

    if (jwtTokenExp == undefined)
      return -1

    return Math.round(jwtTokenExp - Date.now() / 1000)
  }

  return {
    getAccessToken,
    accessTokenIsValid,
    getAccessTokenExpirationInSeconds
  }
}
