import {useDateUtils} from '@/composition/useDateUtils'
import {useStore} from '@/store/store'

export class Message {
  static readonly ERROR = 'error'
  static readonly SUCCESS = 'success'
  static nextId = 0

  id: number
  type: string
  content: string
  createdAt: number

  constructor(type: string, message: string) {
    this.id = Message.nextId++
    this.type = type
    this.content = message
    this.createdAt = new Date().getTime()
  }
  
  get createdAtFormatted(): string {
    return useDateUtils().timestampToString(this.createdAt / 1000, useStore().applicationState.currentLang, true)
  }
}