import {EntityStateBase} from '@/store/types/EntityStateBase'
import {ISubscriptionDto} from '@/types/dtos/ISubscriptionDto'
import {Message} from '@/types/Message'

export class SubscriptionsState extends EntityStateBase<ISubscriptionDto> {
  constructor(messages: Message[]) {
    super(messages, 'subscriptions')
  }

  postFetch(): Promise<boolean> {
    return Promise.resolve(false)
  }
}