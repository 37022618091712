import {createApp} from 'vue'
import App from './App.vue'
import {routerWithStore} from './router'
import {store} from '@/store/store'
import Quasar from 'quasar/src/vue-plugin.js';

import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import i18n from './i18n'

const app = createApp(App)
  .use(i18n)
  .use(Quasar, {
    config: {
      brand: {
        primary_default: '#00574F',
        secondary_default: '#16A398'
      }
    },
    plugins: {}
  })

const router = routerWithStore(store)

app.use(router)
app.use(store)
app.mount('#app')
