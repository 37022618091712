import {EmptyFilledForm, IFilledFormDto} from '@/types/dtos/filledForm/IFilledFormDto'
import {EntityStateBase} from '@/store/types/EntityStateBase'
import {Message} from '@/types/Message'
import {EmptyAnswer, IAnswerDto} from '@/types/dtos/filledForm/IAnswerDto'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import axios, {AxiosResponse} from 'axios'
import Cookies from 'universal-cookie'
import {ICreateFilledFormApiResponse} from '@/types/responses/ICreateFilledFormApiResponse'
import {EmptyPhoneNumber} from '@/types/dtos/contact/IPhoneNumberDto'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useUuidUtils} from '@/composition/useUuidUtils'
import {IFaultyQuestionDto} from '@/types/dtos/filledForm/IFaultyQuestionDto'
import {ISectionDto} from '@/types/dtos/form/ISectionDto'
import {IQuestionDto} from '@/types/dtos/form/IQuestionDto'

export class FilledFormsState extends EntityStateBase<IFilledFormDto> {
  cookieDomain: string
  secureCookie: boolean
  userAnswers: IAnswerDto[]
  partnerAnswers: IAnswerDto[]
  childAnswers: IAnswerDto[]
  faultyQuestions: IFaultyQuestionDto[]

  constructor(messages: Message[]) {
    super(messages, 'filled-forms')

    this.cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN
    this.secureCookie = process.env.VUE_APP_SECURE_COOKIE == 'true'
    this.userAnswers = []
    this.partnerAnswers = []
    this.childAnswers = []
    this.faultyQuestions = []
  }

  async postFetch(): Promise<boolean> {
    return Promise.resolve(false)
  }

  // eslint-disable-next-line
  async getCount(filter: string): Promise<boolean> {
    throw Error('Can\'t use getCount(). Instead, use getCountAdvanced().')
  }

  // eslint-disable-next-line
  async fetch(recordsPerPage: number, page: number, filter: string, sortBy: string, desc: boolean): Promise<boolean> {
    throw Error('Can\'t use fetch(). Instead, use fetchAdvanced().')
  }

  /**
   * Checks if a filled form already exists for the current user (based on auth token) and other parameters. When one
   * does, it is fetched and set as `current`. When none is found, a copy of `EmptyFilledForm` is set as current.
   * 
   * @param fiscalYear The fiscal year for which to get the filled form.
   * @param formUrl The form's public URL, used to determine the organization.
   */
  async fetchFilledFormAndSetCurrent(fiscalYear: number, formUrl: string): Promise<boolean> {
    const jwtUtils = useJwtUtils()

    if (!jwtUtils.accessTokenIsValid()) {
      this.resetCurrent()
      return true
    }

    let filledFormId = null

    try {
      const response = await axios.get<string | null>(this.serviceRoot + '/get-filled-form-id-for-user', {
        headers: {Authorization: `Bearer ${jwtUtils.getAccessToken()}`},
        params: {
          fiscalYear: fiscalYear,
          formUrl: formUrl
        }
      })

      filledFormId = response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }

    if (!filledFormId) {
      this.resetCurrent()
      return true
    }

    return await super.fetchAndSetCurrent(filledFormId)
  }

  /**
   * Creates a filled form for the current user (based on auth token) and other parameters. It is then fetched and set
   * as `current`.
   * 
   * @param fiscalYear The fiscal year for which to get the filled form.
   * @param province The province for which to create the filled form.
   * @param formUrl The form's public URL, used to determine the organization.
   */
  async createFilledFormAndSetCurrent(fiscalYear: number, province: string, formUrl: string): Promise<boolean> {
    const jwtUtils = useJwtUtils()

    if (!jwtUtils.accessTokenIsValid()) {
      this.resetCurrent()
      return true
    }

    let filledFormId = null

    try {
      const response = await axios.post<unknown, AxiosResponse<string>>(
        this.serviceRoot + '/create-filled-form-for-user',
        {
          fiscalYear: fiscalYear,
          province: province,
          formUrl: formUrl
        },
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}}
      )

      filledFormId = response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }

    if (!filledFormId) {
      this.resetCurrent()
      return true
    }

    return await super.fetchAndSetCurrent(filledFormId)
  }
  
  async markAsIncomplete(filledFormId: string): Promise<boolean> {
    try {
      await axios.post<undefined, AxiosResponse<IFilledFormDto>>(
        `${this.serviceRoot}/${filledFormId}/mark-as-incomplete`,
        {},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async updatePricing(filledFormId: string, pricing: string): Promise<boolean> {
    try {
      await axios.patch<undefined, AxiosResponse<IFilledFormDto>>(
        `${this.serviceRoot}/${filledFormId}/pricing`,
        {pricing: pricing},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  /**
   * Between user and partner, children questions are always saved with the filled form that was created first.
   * @return The filled form that contains (or should contain) this family's children answers.
   */
  getParentFilledFormForChildren(): IFilledFormDto | undefined {
    if (!this.current)
      return undefined

    return this.current.partnerFilledForm && this.current.partnerFilledForm.createdAt > this.current.createdAt
      ? this.current.partnerFilledForm
      : this.current
  }

  /**
   * By looping through the `questions`, creates a new array with an answer of every question. When `answers` contains
   * a match, it is used. When it doesn't, a new answer is created, correctly initialized and added to the array.
   * 
   * @param filledFormId The `IFilledFormDto`'s ID to which these answers are linked.
   * @param questions Questions for which answers are desired, usually an `ISectionDto`'s list of questions.
   * @param answers Answers from which matching entries might be found and added to the returned array.
   * @param childId An `IChildDto`'s ID to which these answers are linked.
   * 
   * @returns An array containing an answer (new or not) for all `questions` passed.
   */
  getAnswers(filledFormId: string, questions: IQuestionDto[], answers: IAnswerDto[], childId: string | undefined)
    : IAnswerDto[] {
    const output: IAnswerDto[] = []
    
    questions.forEach(question => {
      let answer = childId
        ? answers.find(a => a.questionId === question.id && a.childId === childId)
        : answers.find(a => a.questionId === question.id)
      
      if (answer) {
        output.push(answer)
        return
      }

      answer = JSON.parse(JSON.stringify(EmptyAnswer)) as IAnswerDto
      answer.filledFormId = filledFormId
      answer.childId = childId
      answer.questionId = question.id
      answer.selectedQuestionChoiceIds = []

      output.push(answer)
    })
    
    return output
  }

  /**
   * Finds and/or creates IAnswerDto entries for a section's questions. When a valid `childId` is passed, only
   * `childAnswers` is populated. When `childId` is undefined, this function populates `userAnswers` and
   * `partnerAnswers` (if `current.partnerFilledForm` is set).
   * 
   * @param section The section for which answer arrays should be populated.
   * @param childId The current child's ID, when applicable.
   */
  setCurrentSectionAnswers(section: ISectionDto | undefined, childId: string | undefined): void {
    this.userAnswers = []
    this.partnerAnswers = []
    this.childAnswers = []
    
    if (!this.current || !section)
      return
    
    // Children answers
    if (section.isChildrenOnly) {
      const storeTo = this.getParentFilledFormForChildren()
      
      if (!storeTo || !childId)
        return

      this.childAnswers = this.getAnswers(this.current.id, section.questions, storeTo.answers, childId)
      
      return
    }
    
    // User answers
    this.userAnswers =
      this.getAnswers(this.current.id, section.questions, this.current.answers, undefined)
    
    // Partner answers
    if (this.current.partnerFilledForm) {
      this.partnerAnswers = this.getAnswers(
        this.current.partnerFilledForm.id,
        section.questions,
        this.current.partnerFilledForm.answers,
        undefined)
    }
  }

  getAnswerContent(isPartner: boolean, answerIndex: number): string {
    return isPartner
      ? this.partnerAnswers[answerIndex].content
      : this.userAnswers[answerIndex].content
  }
  
  getChildAnswerContent(answerIndex: number): string {
    return this.childAnswers[answerIndex].content
  }

  updateAnswerContent(isPartner: boolean, answerIndex: number, content: string): void {
    if (isPartner)
      this.partnerAnswers[answerIndex].content = content
    else
      this.userAnswers[answerIndex].content = content
  }

  updateChildAnswerContent(answerIndex: number, content: string): void {
    this.childAnswers[answerIndex].content = content
  }
  
  clearAnswerContentAndSelectedChoices(isPartner: boolean, answerIndex: number): void {
    if (isPartner) {
      this.partnerAnswers[answerIndex].content = ''
      this.partnerAnswers[answerIndex].selectedQuestionChoiceIds = []
    }
    else {
      this.userAnswers[answerIndex].content = ''
      this.userAnswers[answerIndex].selectedQuestionChoiceIds = []
    }
  }

  clearChildAnswerContentAndSelectedChoices(answerIndex: number): void {
    this.childAnswers[answerIndex].content = ''
    this.childAnswers[answerIndex].selectedQuestionChoiceIds = []
  }

  clearAllFaultyQuestions(): void {
    this.faultyQuestions = []
  }

  /**
   * Determines which partner the child-related answers should be linked to, and saves them.
   */
  async saveChildAnswers(): Promise<boolean> {
    if (!this.current)
      return false

    const storeTo = this.getParentFilledFormForChildren()

    if (!storeTo)
      return false

    storeTo.answers = this.childAnswers

    const success = await this.update(storeTo.id, storeTo, false)

    if (success) {
      await this.fetchAndSetCurrent(this.current.id)
    }

    return success
  }

  async savePartnerFilledForm(): Promise<boolean> {
    if (!this.current || !this.current.partnerFilledForm ||
      this.current.partnerFilledForm.id == useUuidUtils().EmptyUuid) {
      return true // Means there is no partner form to save... all good!
    }

    // Only the current section's answers are saved
    this.current.partnerFilledForm.answers = this.partnerAnswers

    return await this.update(
      this.current.partnerFilledForm.id,
      this.current.partnerFilledForm,
      false)
  }

  /**
   * Saves the user's filled form, which updates the `current` filled form.
   */
  async saveUserFilledForm(): Promise<boolean> {
    if (!this.current || this.current.id == useUuidUtils().EmptyUuid) {
      return false // This should never happen
    }

    // Only the current section's answers are saved
    this.current.answers = this.userAnswers

    return await this.update(this.current.id, this.current)
  }

  /**
   * Assigns a copy of `EmptyFilledForm` to `current`, and copies a single `EmptyPhoneNumber` to `current.phoneNumbers`.
   */
  resetCurrent(): void {
    this.current = Object.assign({}, EmptyFilledForm)
    this.current.user.phoneNumbers.push(Object.assign({}, EmptyPhoneNumber))
  }

  /**
   * Sends an API request that creates a `FilledForm` and a `User`. The API's response will contain a JWT bearer token
   * that this function will save as a cookie. It is recommended to call `AuthenticationState`'s
   * `fetchAuthenticatedUser()` right after.
   * @param formId
   * @param organizationId
   * @return `true` on success, `false` otherwise.
   */
  async createFilledFormAndLogIn(formId: string, organizationId: string): Promise<boolean> {
    const axiosUtils = useAxiosUtils()

    if (!this.current)
      return false

    this.current.formId = formId
    this.current.organizationId = organizationId

    try {
      const response = await axios.post<IFilledFormDto, AxiosResponse<ICreateFilledFormApiResponse>>(
        this.serviceRoot,
        this.current
      )

      this.current = response.data.filledForm

      // Create cookie with bearer token
      const cookies = new Cookies()
      cookies.set('accessToken', response.data.accessToken,
        {
          path: '/',
          domain: `.${this.cookieDomain}`,
          httpOnly: false,
          secure: this.secureCookie,
          sameSite: 'strict'
        })

      return true
    } catch (e) {
      this.messages.push(axiosUtils.errorToMessage(e))
      return false
    }
  }

  async validateFilledForm(entityId: string): Promise<boolean> {
    this.clearAllFaultyQuestions()
    
    try {
      const response = await axios.post<undefined, AxiosResponse<IFaultyQuestionDto[]>>(
        this.serviceRoot + '/' + entityId + '/validate',
        undefined,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.faultyQuestions = response.data
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async completeFilledForm(entityId: string): Promise<boolean> {
    try {
      await axios.put<undefined, AxiosResponse<IFilledFormDto>>(
        this.serviceRoot + '/' + entityId + '/complete',
        undefined,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      await this.fetchAndSetCurrent(entityId)
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async assignSecureSpace(secureSpaceUrl: string): Promise<boolean> {
    if (!this.current)
      return false
    
    try {
      await axios.patch<undefined, AxiosResponse<IFilledFormDto>>(
        this.serviceRoot + '/' + this.current.id + '/assign-secure-space',
        {secureSpaceUrl: secureSpaceUrl},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      await this.fetchAndSetCurrent(this.current.id)
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async getTmdShareUrl(entityId: string): Promise<string> {
    try {
      const response = await axios.get<string>(
        this.serviceRoot + '/' + entityId + '/get-tmd-share-url',
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return ''
    }
  }
  
  async downloadAsPdf(entityId: string, fileName:string, lang: string): Promise<boolean> {
    try {
      const response = await axios.get(
        this.serviceRoot + '/' + entityId + '/download-as-pdf/' + lang,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}, responseType: 'blob'})

      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const aElement = document.createElement('a')

      aElement.href = fileURL;
      aElement.setAttribute('download', fileName + '.pdf');
      document.body.appendChild(aElement);

      aElement.click();
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async createTmdShare(entityId: string): Promise<boolean> {
    try {
      await axios.post<undefined, AxiosResponse<IFilledFormDto>>(
        this.serviceRoot + '/' + entityId + '/create-tmd-share',
        undefined,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async softDelete(filledFormId: string): Promise<boolean> {
    try {
      await axios.delete(
        `${this.serviceRoot}/${filledFormId}`,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async sendPdfsToMonday(entityId: string): Promise<boolean> {
    try {
      await axios.post<undefined, AxiosResponse>(
        this.serviceRoot + '/' + entityId + '/send-pdfs-to-monday',
        undefined,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}
