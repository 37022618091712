import {EntityStateBase} from '@/store/types/EntityStateBase'
import {IEntityWithTmdAccount} from '@/types/dtos/IEntityWithTmdAccount'
import {TmdAccountStateDelegate} from '@/store/types/delegates/TmdAccountStateDelegate'

export abstract class EntityWithTmdAccountStateBase<T extends IEntityWithTmdAccount> extends EntityStateBase<T> {

  async postFetch(): Promise<boolean> {
    return await this.fetchAndSetTmdAccount()
  }

  async fetchAndSetTmdAccount(): Promise<boolean> {
    if (this.current === undefined)
      return false

    const delegate = new TmdAccountStateDelegate() // TODO: this should be injected
    const response = await delegate.fetchTmdAccount(this.serviceRoot, this.current.id)
    
    if (delegate.isTmdAccount(response)) {
      this.current.tmdAccount = response
      return true
    }

    if (delegate.isMessage(response))
      this.messages.push(response)
    
    return false
  }
}
