import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import axios, {AxiosResponse} from 'axios'
import {IEntityDto} from '@/types/dtos/IEntityDto'
import {useJwtUtils} from '@/composition/useJwtUtils'

export abstract class EntityStateBase<T extends IEntityDto> extends StateBase {
  /** The "current" `T` entity, usually meaning the one currently being shown or edited. */
  current: T | undefined
  /** The count set by the latest call to `getCount()`. */
  count: number
  /** All the fetched `T` entities. */
  fetched: T[]

  protected constructor(messages: Message[], serviceBasePath: string) {
    super(messages, serviceBasePath)
    this.current = undefined
    this.count = 0
    this.fetched = []
  }

  logOut(): void {
    this.current = undefined
    this.count = 0
    this.fetched = []
  }

  abstract postFetch(): Promise<boolean>

  /**
   * Gets the number of entities found in the database and assigns the result to `this.count`. If a non-empty filter is
   * passed, the API service will try to match one or more entity properties and return that count. The matching rules
   * are always defined in the back-end (services and filter models). Most services will do a "CI AI" exact match (as
   * in no fuzziness).
   * @param filter A string filter used to match one or more entity properties, based on the service's business rules.
   * @return `true` on success, `false` otherwise.
   */
  async getCount(filter: string): Promise<boolean> {
    return await this.getCountAdvanced(filter, undefined)
  }

  /**
   Gets the number of entities found in the database and assigns the result to `this.count`. If a non-empty filter is
   passed, the API service will try to match one or more entity properties and return that count. The matching rules
   are always defined in the back-end (services and filter models). Most services will do a "CI AI" exact match (as
   in no fuzziness).
   * @param filter A string filter used to match one or more entity properties, based on the service's business rules.
   * @param extraParams Additional parameters to pass to the API call.
   * @return `true` on success, `false` otherwise.
   */
  // eslint-disable-next-line
  async getCountAdvanced(filter: string, extraParams: any): Promise<boolean> {
    const params = extraParams ? {...{filter: filter}, ...extraParams} : {filter: filter}

    try {
      const response = await axios.get<number>(this.serviceRoot + '/count', {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
        params: params
      })

      this.count = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.count = 0
      return false
    }
  }

  /**
   * Fetches one or more entity records and assigns them to `this.fetched`. paging, filtering and sorting options are
   * all mandatory. The API service will return a 422 HTTP error if too many records are requested.
   * @param recordsPerPage The number of records per page. API services return only one result page at a time.
   * @param page 1-based page number.
   * @param filter A string filter used to match one or more entity properties, based on the service's business rules.
   *               Look at `getCount()` for more information on this.
   * @param sortBy An entity's property name by which results will be sorted.
   * @param desc Should the sorting be descending (`true`) or ascending (`false`).
   * @return `true` on success, `false` otherwise.
   */
  async fetch(recordsPerPage: number, page: number, filter: string, sortBy: string, desc: boolean): Promise<boolean> {
    return await this.fetchAdvanced(recordsPerPage, page, filter, sortBy, desc,undefined)
  }

  /**
   * Fetches one or more entity records and assigns them to `this.fetched`. paging, filtering and sorting options are
   * all mandatory. The API service will return a 422 HTTP error if too many records are requested.
   * @param recordsPerPage The number of records per page. API services return only one result page at a time.
   * @param page 1-based page number.
   * @param filter A string filter used to match one or more entity properties, based on the service's business rules.
   *               Look at `getCount()` for more information on this.
   * @param sortBy An entity's property name by which results will be sorted.
   * @param desc Should the sorting be descending (`true`) or ascending (`false`).
   * @param extraParams Additional parameters to pass to the API call.
   * @return `true` on success, `false` otherwise.
   */
  // eslint-disable-next-line
  async fetchAdvanced(recordsPerPage: number, page: number, filter: string, sortBy: string, desc: boolean, extraParams: any)
    : Promise<boolean> {
    
    let params = {
      recordsPerPage: recordsPerPage,
      page: page,
      filter: filter,
      sortBy: sortBy,
      desc: desc
    }

    if (extraParams)
      params = {...params, ...extraParams}

    try {
      const response = await axios.get<T[]>(this.serviceRoot, {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
        params: params
      })

      this.fetched = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }
  }

  /**
   * Fetches a single `T` entity and assigns it to `this.current`.
   * @param id The entity's ID (a GUID).
   * @return `true` on success, `false` otherwise.
   */
  async fetchAndSetCurrent(id: string): Promise<boolean> {
    try {
      const response = await axios.get<T>(
        this.serviceRoot + '/' + id,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      this.current = response.data

      await this.postFetch()

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.current = undefined
      return false
    }
  }

  /**
   * Sends a `T` entity to the API service's `POST` method, waits for the result and assigns it to `this.current`.
   * @param entity A new, unsaved entity. This entity will be added (not updated) to the database.
   * @param setAsCurrent Whether or not the newly created entity should be assigned to`this.current` on success.
   * @return `true` on success, `false` otherwise.
   */
  async create(entity: T, setAsCurrent = true): Promise<boolean> {
    try {
      const response = await axios.post<T, AxiosResponse<T>>(
        this.serviceRoot,
        entity,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      if (setAsCurrent)
        this.current = response.data
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  /**
   * Sends a `T` entity to the API service's `PUT` method, waits for the result and assigns it to `this.current`.
   * @param entityId The entity's ID (a GUID).
   * @param entity The entity to save / update. This entity must already exist in the database.
   * @param setAsCurrent Whether or not the updated entity should be assigned to`this.current` on success.
   * @return `true` on success, `false` otherwise.
   */
  async update(entityId: string, entity: T, setAsCurrent = true): Promise<boolean> {
    try {
      const response = await axios.put<T, AxiosResponse<T>>(
        this.serviceRoot + '/' + entityId,
        entity,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      if (setAsCurrent)
        this.current = response.data
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  /**
   * Calls the API service's `DELETE` method, which soft deletes the entity.
   * @param entityId The entity's ID (a GUID).
   * @return `true` on success, `false` otherwise.
   */
  async delete(entityId: string): Promise<boolean> {
    try {
      await axios.delete(
        this.serviceRoot + '/' + entityId,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}
