import {App, inject, reactive} from "vue"
import {UsersState} from "@/store/types/UsersState";
import {AuthenticationState} from "@/store/types/AuthenticationState";
import {OrganizationsState} from "@/store/types/OrganizationsState";
import {ApplicationState} from "@/store/types/ApplicationState";
import {Message} from "@/types/Message";
import {FormsState} from "@/store/types/FormsState";
import {FilledFormsState} from "@/store/types/FilledFormsState";
import {TmdFilesState} from '@/store/types/TmdFilesState'
import {ChildrenState} from '@/store/types/ChildrenState'
import {FilledFormDocumentListsState} from '@/store/types/FilledFormDocumentListsState'
import {FilledFormAssignmentsState} from '@/store/types/FilledFormAssignmentsState'
import {AdminStatsState} from '@/store/types/AdminStatsState'
import {PlansState} from '@/store/types/PlansState'
import {SubscriptionsState} from '@/store/types/SubscriptionState'
import {NotificationsState} from '@/store/types/NotificationsState'
import {TmdSecureSpacesState} from '@/store/types/TmdSecureSpacesState'

export interface State {
  application: ApplicationState
  authentication: AuthenticationState
  users: UsersState,
  children: ChildrenState,
  organizations: OrganizationsState,
  forms: FormsState,
  filledForms: FilledFormsState,
  filledFormAssignments: FilledFormAssignmentsState,
  filledFormDocumentLists: FilledFormDocumentListsState,
  tmdFiles: TmdFilesState,
  tmdSecureSpaces: TmdSecureSpacesState,
  adminStatsState: AdminStatsState,
  plansState: PlansState,
  subscriptionsState: SubscriptionsState,
  notificationsState: NotificationsState,
}

export const storeKey = Symbol('store')

export class Store {
  private readonly messages: Message[]
  private readonly state: State

  constructor() {
    this.messages = reactive<Message[]>([])
    this.state = reactive<State>({
      application: new ApplicationState(),
      authentication: new AuthenticationState(this.messages),
      users: new UsersState(this.messages),
      children: new ChildrenState(this.messages),
      organizations: new OrganizationsState(this.messages),
      forms: new FormsState(this.messages),
      filledForms: new FilledFormsState(this.messages),
      filledFormAssignments: new FilledFormAssignmentsState(this.messages),
      filledFormDocumentLists: new FilledFormDocumentListsState(this.messages),
      tmdFiles: new TmdFilesState(this.messages),
      tmdSecureSpaces: new TmdSecureSpacesState(this.messages),
      adminStatsState: new AdminStatsState(this.messages),
      plansState: new PlansState(this.messages),
      subscriptionsState: new SubscriptionsState(this.messages),
      notificationsState: new NotificationsState(this.messages)
    })
  }

  install(app: App): void {
    app.provide(storeKey, this)
  }
  
  get globalMessages(): Message[] {
    return this.messages
  }

  clearAllMessages(): void {
    this.messages.length = 0
  }
  
  logOut(): void {
    this.state.application.logOut()
    this.state.authentication.logOut()
    this.state.users.logOut()
    this.state.children.logOut()
    this.state.organizations.logOut()
    this.state.forms.logOut()
    this.state.filledForms.logOut()
    this.state.filledFormDocumentLists.logOut()
    this.state.tmdFiles.logOut()
    this.state.adminStatsState.logOut()
    this.state.plansState.logOut()
    this.state.subscriptionsState.logOut()
    this.state.notificationsState.logOut()
    
    this.clearAllMessages()
  }

  get applicationState(): ApplicationState {
    return this.state.application
  }

  get authenticationState(): AuthenticationState {
    return this.state.authentication
  }

  get usersState(): UsersState {
    return this.state.users
  }

  get childrenState(): ChildrenState {
    return this.state.children
  }

  get organizationsState(): OrganizationsState {
    return this.state.organizations
  }

  get formsState(): FormsState {
    return this.state.forms
  }

  get filledFormsState(): FilledFormsState {
    return this.state.filledForms
  }

  get filledFormAssignmentsState(): FilledFormAssignmentsState {
    return this.state.filledFormAssignments
  }

  get filledFormDocumentListsState(): FilledFormDocumentListsState {
    return this.state.filledFormDocumentLists
  }

  get tmdFilesState(): TmdFilesState {
    return this.state.tmdFiles
  }
  
  get tmdSecureSpacesState(): TmdSecureSpacesState {
    return this.state.tmdSecureSpaces
  }

  get adminStatsState(): AdminStatsState {
    return this.state.adminStatsState
  }

  get plansState(): PlansState {
    return this.state.plansState
  }

  get subscriptionsState(): SubscriptionsState {
    return this.state.subscriptionsState
  }

  get notificationsState(): NotificationsState {
    return this.state.notificationsState
  }
}

export const store = new Store()

export function useStore(): Store {
  const injectedStore = inject<Store>(storeKey)

  if (!injectedStore) {
    throw Error('Store was not injected. Did you forget to call provide?')
  }
  
  return injectedStore
}
