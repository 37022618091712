import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import axios, {AxiosResponse} from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {ITmdNewFileNotificationDto} from '@/types/dtos/notification/ITmdNewFileNotificationDto'

export class NotificationsState extends StateBase {
  fetched: ITmdNewFileNotificationDto[]
  
  constructor(messages: Message[]) {
    super(messages, 'notifications')
    this.fetched = []
  }

  logOut(): void {
    this.fetched = []
  }

  async fetch(): Promise<boolean> {
    try {
      const response = await axios.get<ITmdNewFileNotificationDto[]>(this.serviceRoot, {
        headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
      })

      this.fetched = response.data
      return true
    } catch (e) {
      this.fetched = []
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async markAsRead(notification: ITmdNewFileNotificationDto): Promise<boolean> {
    if (!notification)
      return false
    
    try {
      const response = await axios.put<unknown, AxiosResponse<boolean>>(
        `${this.serviceRoot}/mark-as-read`,
        {
          ids: notification.ids
        },
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
        }
      )
      
      if (response.data)
        this.fetched.splice(this.fetched.indexOf(notification), 1)
      
      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}