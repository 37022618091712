import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {ITmdFileDto} from '@/types/dtos/ITmdFileDto'
import axios, {AxiosResponse} from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'

export class TmdFilesState extends StateBase {
  secureCookie: boolean
  current: ITmdFileDto | undefined
  fetchedTaxPayerFiles: ITmdFileDto[]
  fetchedOrganizationFiles: ITmdFileDto[]

  constructor(messages: Message[]) {
    super(messages, 'tmd-files')
    this.secureCookie = process.env.VUE_APP_SECURE_COOKIE == 'true'
    this.current = undefined
    this.fetchedTaxPayerFiles = []
    this.fetchedOrganizationFiles = []
  }

  logOut(): void {
    this.current = undefined
    this.fetchedTaxPayerFiles = []
    this.fetchedOrganizationFiles = []
  }
  
  async userHasTaxFolderWithOrganization(year: number, taxPayerId: string, organizationId: string): Promise<boolean> {
    try {
      const response = await axios.get<boolean>(
        `${this.serviceRoot}/user-has-tax-folder-with-organization/${year}/${taxPayerId}/${organizationId}`,
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      return response.data
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  async fetchTaxPayerFiles(fiscalYear: number, taxPayerId: string, organizationId: string): Promise<boolean> {
    try {
      const response = await axios.get<ITmdFileDto[]>(
        `${this.serviceRoot}/user-yearly-tax-files/${fiscalYear}/${taxPayerId}/${organizationId}`,
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      this.fetchedTaxPayerFiles = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.fetchedTaxPayerFiles = []
      return false
    }
  }

  async fetchOrganizationFiles(fiscalYear: number, taxPayerId: string, organizationId: string): Promise<boolean> {
    try {
      const response = await axios.get<ITmdFileDto[]>(
        `${this.serviceRoot}/yearly-tax-return-files/${fiscalYear}/${taxPayerId}/${organizationId}`,
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      this.fetchedOrganizationFiles = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.fetchedOrganizationFiles = []
      return false
    }
  }
  
  async download(fileId: string): Promise<boolean> {
    if (!fileId || fileId.length == 0)
      return false
    
    try {
      const response = await axios.get<string>(
        `${this.serviceRoot}/${fileId}/get-download-url`,
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      if (!response.data || response.data.length == 0)
        return false
      
      // Triggers the download
      window.location.href = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async downloadAllFilledFormFiles(
    fiscalYear: number,
    taxPayerId: string,
    organizationId: string,
    fileName: string,
    language: string)
    : Promise<boolean> {
    try {
      const response = await axios.get(
        `${this.serviceRoot}/user-yearly-tax-files/${fiscalYear}/${taxPayerId}/${organizationId}/download-all`,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}, responseType: 'blob'})

      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const aElement = document.createElement('a')

      aElement.href = fileURL;
      aElement.setAttribute('download', fileName + '.zip');
      document.body.appendChild(aElement);

      aElement.click();

      return true
    } catch (e) {
      const errorData = useAxiosUtils().errorToErrorData(e)
      
      if (errorData.status === 413) {
        this.messages.push(new Message(Message.ERROR, language === 'en'
          ? 'The archive is too big to be downloaded. Please download each file individually. This is a Convoflo limitation.'
          : `L'archive est trop volumineuse pour être téléchargée. Veuillez télécharger chaque document individuellement. Ceci est une limitation de Convoflo.`)
        )
      }
      else if (errorData.status === 500) {
        this.messages.push(new Message(Message.ERROR, language === 'en'
          ? 'An error has occured. Our technical team has been contacted.'
          : 'Une erreur est survenue. Notre équipe technique a été contactée.')
        )
      }
      
      return false
    }
  }
  
  async uploadOrganizationFile(fiscalYear: number, taxPayerId: string, organizationId: string, file: File): Promise<boolean> {
    try {
      const formData = new FormData()
      formData.append('file', file)
      
      const response = await axios.post<FormData, AxiosResponse>(
        this.serviceRoot + '/organization-files/' + fiscalYear + '/' + taxPayerId + '/' + organizationId + '/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${useJwtUtils().getAccessToken()}`
          }
        }
      )
      
      this.fetchedOrganizationFiles.push(response.data)
      
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async deleteFile(fileId: string): Promise<boolean> {
    try {
      await axios.delete<AxiosResponse>(
        this.serviceRoot + '/' + fileId,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        }
      )

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
  
  async notifyTaxPayer(fiscalYear: number, taxPayerId: string, organizationId: string): Promise<boolean> {
    try {
      await axios.post<undefined, AxiosResponse>(
        this.serviceRoot + '/organization-files/' + fiscalYear + '/' + taxPayerId + '/' + organizationId + '/notify',
        undefined,
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        }
      )

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}
