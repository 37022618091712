import { v4 as uuidv4 } from 'uuid'

// eslint-disable-next-line
export function useUuidUtils() {
  /**
   * An empty UUID. This does not follow the RFC4122 spec, but makes things a lot easier when dealing with a .Net API.
   */
  const EmptyUuid = '00000000-0000-0000-0000-000000000000'
  
  /**
   * Generates and returns a UUID (v4).  
   */
  function generateId(): string {
    return uuidv4()
  }
  
  return {
    EmptyUuid,
    generateId
  }
}