import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {ITmdFolderDto} from '@/types/dtos/ITmdFolderDto'
import axios from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'

export class TmdSecureSpacesState extends StateBase {
  secureCookie: boolean
  current: ITmdFolderDto | undefined
  fetched: ITmdFolderDto[]
  
  constructor(messages: Message[]) {
    super(messages, 'tmd-secure-spaces')
    this.secureCookie = process.env.VUE_APP_SECURE_COOKIE == 'true'
    this.current = undefined
    this.fetched = []
  }

  logOut(): void {
    this.current = undefined
    this.fetched = []
  }
  
  async fetchAndSetSecureSpaces(formUrl: string): Promise<boolean> {
    try {
      const response = await axios.get<ITmdFolderDto[]>(
        `${this.serviceRoot}?formUrl=${formUrl}`,
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      this.fetched = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.fetched = []
      return false
    }
  }
}