import {RouteRecordRaw} from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: '/pre-tax-form-as-pro',
        name: 'PreTaxFormAsPro',
        component: () => import('../pages/pre-tax-forms/PreTaxFormAsProPage.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/form/:formUrl',
    component: () => import('../layouts/PreTaxFormLayout.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'PreTaxFormLogin',
        component: () => import('../pages/pre-tax-forms/PreTaxFormLoginPage.vue')
      },
      {
        path: 'intro',
        name: 'PreTaxFormIntro',
        component: () => import('../pages/pre-tax-forms/PreTaxFormIntroPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user-info',
        name: 'PreTaxFormUserInfo',
        component: () => import('../pages/pre-tax-forms/PreTaxFormUserPage.vue'),
        props: () => ({
          pageMode: 'user'
        }),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'partner-info',
        name: 'PreTaxFormPartnerInfo',
        component: () => import('../pages/pre-tax-forms/PreTaxFormUserPage.vue'),
        props: () => ({
          pageMode: 'partner'
        }),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'children-info',
        name: 'PreTaxFormChildrenInfo',
        component: () => import('../pages/pre-tax-forms/PreTaxFormChildrenPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'section/:order/:childIndex?',
        name: 'PreTaxFormSection',
        component: () => import('../pages/pre-tax-forms/PreTaxFormSectionPage.vue'),
        props: (route) => ({
          order: parseInt(route.params['order'] as string),
          childIndex: route.params['childIndex'] ? parseInt(route.params['childIndex'] as string) : undefined
        }),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'select-secure-space',
        name: 'PreTaxFormSecureSpaceSelect',
        component: () => import('../pages/pre-tax-forms/PreTaxFormSecureSpaceSelectPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'end',
        name: 'PreTaxFormEnd',
        component: () => import('../pages/pre-tax-forms/PreTaxFormEndPage.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/users',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'UsersDashboard',
        component: () => import('../pages/users/UsersDashboard.vue'),
      },
      {
        path: 'create',
        name: 'CreateUser',
        component: () => import('../pages/users/UserForm.vue'),
      },
      {
        path: ':id',
        name: 'ViewUser',
        component: () => import('../pages/users/UserView.vue'),
        props: true
      },
      {
        path: ':id/edit',
        name: 'EditUser',
        component: () => import('../pages/users/UserForm.vue'),
        props: true
      }
    ]
  },
  {
    path: '/organizations',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'OrganizationsDashboard',
        component: () => import('../pages/organizations/OrganizationsDashboard.vue'),
      },
      {
        path: 'create',
        name: 'CreateOrganization',
        component: () => import('../pages/organizations/OrganizationForm.vue'),
      },
      {
        path: ':id',
        name: 'ViewOrganization',
        component: () => import('../pages/organizations/OrganizationView.vue'),
        props: true
      },
      {
        path: ':id/edit',
        name: 'EditOrganization',
        component: () => import('../pages/organizations/OrganizationForm.vue'),
        props: true
      },
      {
        path: ':id/add-user/:role',
        name: 'AddUserToOrganization',
        component: () => import('../pages/organizations/AddUserToOrganizationForm.vue'),
        props: true
      },
      {
        path: ':id/subscription',
        name: 'EditOrganizationSubscription',
        component: () => import('../pages/organizations/OrganizationSubscriptionForm.vue'),
        props: true
      }
    ]
  },
  {
    path: '/filled-forms',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'FilledFormsDashboard',
        component: () => import('../pages/filled-forms/FilledFormsDashboard.vue'),
      },
      {
        path: ':id',
        name: 'ViewFilledForm',
        component: () => import('../pages/filled-forms/FilledFormView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/admin-stats',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'AdminStatsDashboard',
        component: () => import('../pages/admin-stats/AdminStatsDashboard.vue'),
      }
    ]
  },
  {
    path: '/plans',
    component: () => import('../layouts/OfficeLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'PlansDashboard',
        component: () => import('../pages/plans/PlansDashboard.vue'),
      },
      {
        path: 'create',
        name: 'CreatePlan',
        component: () => import('../pages/plans/PlanForm.vue'),
      },
      {
        path: ':id',
        name: 'ViewPlan',
        component: () => import('../pages/plans/PlanView.vue'),
        props: true
      },
      {
        path: ':id/edit',
        name: 'EditPlan',
        component: () => import('../pages/plans/PlanForm.vue'),
        props: true
      }
    ]
  }
]
