import axios from 'axios'
import {EmptyTmdAccountDto, ITmdAccountDto} from '@/types/dtos/ITmdAccountDto'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {Message} from '@/types/Message'

/**
 * A delegate used by certain `StateBase` classes to fetch an entity's TagMyDoc account.
 */
export class TmdAccountStateDelegate {

  /**
   * Fetches a TMD account linked to a specific entity of a type that usually extends `IEntityWithTmdAccount`.
   * 
   * @param serviceRootUrl The API service root, for example: '/api/users'
   * @param entityId The ID of the entity for which the TMD account will be fetched.
   * @return An `ITmdAccountDto` object on success, an error `Message` object otherwise.
   */
  async fetchTmdAccount(serviceRootUrl: string, entityId: string): Promise<ITmdAccountDto | Message> {
    if (!serviceRootUrl || serviceRootUrl.length == 0)
      return new Message(Message.ERROR, 'serviceRootUrl must be set')
    
    if (!entityId || entityId.length == 0 || entityId == '00000000-0000-0000-0000-000000000000')
      return new Message(Message.ERROR, 'entityId must be set')

    try {
      const response = await axios.get<ITmdAccountDto>(
        serviceRootUrl + '/' + entityId + '/tmd-account',
        {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
        })

      if (response.status == 204) {
        return EmptyTmdAccountDto
      }

      return response.data
    } catch (e) {
      return useAxiosUtils().errorToMessage(e)
    }
  }

  /**
   * Checks if object is of type `ITmdAccountDto`.
   * @param obj
   * @return `true` when of type `ITmdAccountDto`, `false` otherwise.
   */
  // eslint-disable-next-line
  isTmdAccount(obj: any): obj is ITmdAccountDto {
    return obj.email !== undefined
  }

  /**
   * Checks if object is of type `Message`.
   * @param obj
   * @return `true` when of type `Message`, `false` otherwise.
   */
  // eslint-disable-next-line
  isMessage(obj: any): obj is Message {
    return obj.content !== undefined
  }
}