import {EntityStateBase} from '@/store/types/EntityStateBase'
import {IChildDto} from '@/types/dtos/IChildDto'
import {Message} from '@/types/Message'
import {useJwtUtils} from '@/composition/useJwtUtils'
import axios from 'axios'
import {useAxiosUtils} from '@/composition/useAxiosUtils'

export class ChildrenState extends EntityStateBase<IChildDto> {
  
  constructor(messages: Message[]) {
    super(messages, 'children')
  }
  
  postFetch(): Promise<boolean> {
    return Promise.resolve(false);
  }
  
  async fetchChildrenOfUser(userId: string): Promise<boolean> {
    const jwtUtils = useJwtUtils()

    try {
      const response = await axios.get<IChildDto[]>(this.serviceRoot + '/of-user/' + userId, {
        headers: {Authorization: `Bearer ${jwtUtils.getAccessToken()}`},
      })

      this.fetched = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.fetched = []
      return false
    }
  }
  
  async fetchForAuthenticatedUser(): Promise<boolean> {
    const jwtUtils = useJwtUtils()

    try {
      const response = await axios.get<IChildDto[]>(this.serviceRoot, {
        headers: {Authorization: `Bearer ${jwtUtils.getAccessToken()}`},
      })

      this.fetched = response.data
      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      this.fetched = []
      return false
    }
  }

  async removeMeAsParent(childId: string): Promise<boolean> {
    try {
      await axios.post(
        `${this.serviceRoot}/${childId}/remove-me-as-parent`,
        {},
        {headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}})

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }
}