import {IAnswerDto} from '@/types/dtos/filledForm/IAnswerDto'
import {EmptyUser, IUserDto} from '@/types/dtos/IUserDto'
import {EmptyForm, IFormDto} from '@/types/dtos/form/IFormDto'
import {IFilledFormAssignmentDto} from '@/types/dtos/filledForm/IFilledFormAssignmentDto'

export interface IFilledFormDto {
  id: string
  formId: string
  form: IFormDto
  userId: string
  user: IUserDto
  organizationId: string
  pricing: string
  status: string
  createdAt: number
  updatedAt: number | undefined
  firstCompletedAt: number | undefined
  answers: IAnswerDto[]
  partnerFilledForm: IFilledFormDto | undefined
  assignment: IFilledFormAssignmentDto | undefined
  sentToMonday: boolean
  familyCompletionPercentage: number
}

export const EmptyFilledForm: IFilledFormDto = {
  id: '00000000-0000-0000-0000-000000000000',
  formId: '00000000-0000-0000-0000-000000000000',
  form: Object.assign({}, EmptyForm),
  userId: '00000000-0000-0000-0000-000000000000',
  user: Object.assign({}, EmptyUser),
  organizationId: '00000000-0000-0000-0000-000000000000',
  pricing: '',
  status: 'New',
  createdAt: -2000000000,
  updatedAt: undefined,
  firstCompletedAt: undefined,
  answers: [],
  partnerFilledForm: undefined,
  assignment: undefined,
  sentToMonday: false,
  familyCompletionPercentage: -1
}
