export interface ITmdAccountDto {
  id: string
  email: string
  usesApiPlan: boolean | null
  status: string
}

export const EmptyTmdAccountDto: ITmdAccountDto = {
  id: '00000000-0000-0000-0000-000000000000',
  email: '',
  usesApiPlan: null,
  status: 'NotCreated'
}