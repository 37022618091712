import axios, {AxiosResponse} from 'axios'
import {EmptyUser, IUserDto} from '@/types/dtos/IUserDto'
import {ILogInApiResponse} from '@/types/responses/ILogInApiResponse'
import Cookies from 'universal-cookie'
import {IMeApiResponse} from '@/types/responses/IMeApiResponse'
import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {TmdAccountStateDelegate} from '@/store/types/delegates/TmdAccountStateDelegate'
import {IRefreshTokenApiResponse} from '@/types/responses/IRefreshTokenApiResponse'
import {useAxiosUtils} from '@/composition/useAxiosUtils'
import {IRequestLogInCodeResponse} from '@/types/responses/IRequestLogInCodeResponse'

export class AuthenticationState extends StateBase {
  cookieDomain: string
  secureCookie: boolean
  authenticatedUser: IUserDto
  intervalId: number | undefined

  public constructor(messages: Message[]) {
    super(messages, 'authentication')
    
    this.cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN
    this.secureCookie = process.env.VUE_APP_SECURE_COOKIE == 'true'
    this.authenticatedUser = Object.assign({}, EmptyUser)
    
    this.intervalId = setInterval(
      async () => {
        await this.refreshAuthToken()
      },
      process.env.VUE_APP_AUTH_REFRESH_INTERVAL >= 60 // minimum is 60 seconds
        ? process.env.VUE_APP_AUTH_REFRESH_INTERVAL * 1000
        : 3600000) // defaults to 1 hour
  }

  isAuthenticated(): boolean {
    if (!useJwtUtils().accessTokenIsValid()) {
      this.logOut()
      return false
    }

    return true
  }

  async fetchAuthenticatedUser(): Promise<void> {
    if (!this.isAuthenticated())
      return

    const response = await axios.get<IMeApiResponse>(this.serviceRoot + '/me', {
      headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`}
    })

    this.authenticatedUser = response.data.user

    if (this.authenticatedUser) {
      await this.refreshAuthToken()
    }
  }

  async fetchAuthenticatedUserTmdAccount(): Promise<void> {
    const delegate = new TmdAccountStateDelegate() // TODO: this should be injected
    const response = await delegate.fetchTmdAccount(this.apiRoot + 'users', this.authenticatedUser.id)

    if (delegate.isTmdAccount(response)) {
      this.authenticatedUser.tmdAccount = response
      return
    }

    if (delegate.isMessage(response))
      this.messages.push(response)
  }

  isCurrentUserLoaded(): boolean {
    return this.authenticatedUser &&
      this.authenticatedUser.id !== '' &&
      this.authenticatedUser.id !== '00000000-0000-0000-0000-000000000000'
  }

  async refreshAuthToken(): Promise<boolean> {
    if (!this.isCurrentUserLoaded())
      return true

    try {
      const response = await axios.get<IRefreshTokenApiResponse>(this.serviceRoot + '/refresh-token')

      this.onRefreshTokenSuccess(response.data)
      return true
    } catch (e) {
      const errorCode = useAxiosUtils().getErrorCode(e)
      
      if (errorCode == 401 || errorCode == 403) {
        this.logOut()
        window.location.href = '/'
      }

      return false
    }
  }

  async requestLogInLink(userName: string, preTaxFormUrl: string, language: string): Promise<string | undefined> {
    try {
      const response =
        await axios.post<IRequestLogInCodeResponse, AxiosResponse>(`${this.serviceRoot}/request-log-in-code`, {
          emailAddress: userName.trim(),
          preTaxFormUrl: preTaxFormUrl.trim(),
          language: language
        })

      return response.data.goToUrl
    } catch (e) {
      return undefined
    }
  }

  logOut(): void {
    const cookies = new Cookies()
    cookies.remove('accessToken',
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict'
      })

    this.authenticatedUser = Object.assign({}, EmptyUser)
    clearInterval(this.intervalId)
  }

  /**
   * This should be private, but TS private functions are still not possible. Don't call this function. ;-)
   * @param response
   */
  onRefreshTokenSuccess(response: IRefreshTokenApiResponse): void {
    this.setAccessTokenCookie(response)
  }

  /**
   * This should be private, but TS private functions are still not possible. Don't call this function. ;-)
   * @param response
   */
  setAccessTokenCookie(response: ILogInApiResponse | IRefreshTokenApiResponse): void {
    const cookies = new Cookies()
    cookies.set('accessToken', response.accessToken,
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict'
      })
  }
}
