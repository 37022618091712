export interface IAnswerDto {
  id: string
  filledFormId: string
  questionId: string
  childId: string | undefined
  content: string
  selectedQuestionChoiceIds: string[]
}

export const EmptyAnswer: IAnswerDto = {
  id: '00000000-0000-0000-0000-000000000000',
  filledFormId: '00000000-0000-0000-0000-000000000000',
  questionId: '00000000-0000-0000-0000-000000000000',
  childId: undefined,
  content: '',
  selectedQuestionChoiceIds: []
}