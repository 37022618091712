import {StateBase} from '@/store/types/StateBase'
import {Message} from '@/types/Message'
import {IOrganizationQuotaUsageDto} from '@/types/dtos/adminStats/IOrganizationQuotaUsageDto'
import axios from 'axios'
import {useJwtUtils} from '@/composition/useJwtUtils'
import {useAxiosUtils} from '@/composition/useAxiosUtils'

export class AdminStatsState extends StateBase {
  organizationQuotaUsages: IOrganizationQuotaUsageDto[]

  constructor(messages: Message[]) {
    super(messages, 'admin-stats')
    this.organizationQuotaUsages = []
  }

  async fetchOrganizationQuotaUsages(recordsPerPage: number, page: number, desc: boolean): Promise<boolean> {
    const params = {
      recordsPerPage: recordsPerPage,
      page: page,
      desc: desc
    }
    
    try {
      const response = await axios.get<IOrganizationQuotaUsageDto[]>(
        this.serviceRoot + '/organization-quota-usages', {
          headers: {Authorization: `Bearer ${useJwtUtils().getAccessToken()}`},
          params: params
        })

      this.organizationQuotaUsages = response.data

      return true
    } catch (e) {
      this.messages.push(useAxiosUtils().errorToMessage(e))
      return false
    }
  }

  logOut(): void {
    this.organizationQuotaUsages = []
  }
}