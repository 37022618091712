import Cookies from 'universal-cookie'
import {IPaginationSettings} from '@/types/navigation/IPaginationSettings'
import axios from 'axios'

export class ApplicationState {
  cookieDomain: string
  secureCookie: boolean
  serviceRoot: string
  languages: string[]
  phoneTypes: string[]
  provinces: string[]
  genders: string[]
  fiscalYear: number
  selectedFiscalYear: number | undefined
  province: string
  currentLang: string
  paginationSettings: IPaginationSettings

  constructor() {
    this.cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN
    this.secureCookie = process.env.VUE_APP_SECURE_COOKIE == 'true'
    this.serviceRoot = process.env.VUE_APP_API_ROOT + 'app'
    this.languages = ['fr', 'en']
    this.phoneTypes = ['CellPhone', 'Work', 'Home', 'Fax', 'EmergencyLine', 'TollFree', 'Pager', 'HearingImpaired']
    this.provinces = ['', 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'TY']
    this.genders = ['Female', 'Male', 'Other']
    this.fiscalYear = -1
    this.selectedFiscalYear = this.getSelectedFiscalYearFromCookie()
    this.province = ''
    this.currentLang = this.getLanguageFromCookieAndSetDefaultIfNone()
    this.paginationSettings = this.getPaginationFromCookieAndSetDefaultIfNone()
  }

  logOut(): void {
    const cookies = new Cookies()
    cookies.remove('fiscalYear',
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict'
      })
    
    this.selectedFiscalYear = -1
  }

  getLanguageFromCookieAndSetDefaultIfNone(): string {
    const language = new Cookies().get<string>('language')

    if (language && language.length > 0)
      return language.toLowerCase().startsWith('en') ? 'en' : 'fr'

    const defaultLanguage = process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE.toLowerCase() : 'fr'
    this.setLanguageCookie(defaultLanguage)

    return defaultLanguage
  }

  setCurrentLanguage(language: string): void {
    this.currentLang = language
    this.setLanguageCookie(language)
  }

  setLanguageCookie(language: string): void {
    const expires = new Date() // Now
    expires.setUTCFullYear(expires.getUTCFullYear() + 1) // Add one year

    const cookies = new Cookies()
    cookies.set('language', language,
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict',
        expires: expires
      })
  }

  getSelectedFiscalYearFromCookie(): number | undefined {
    const fiscalYear = parseInt(new Cookies().get<string>('fiscalYear'))

    if (fiscalYear && !isNaN(fiscalYear) && fiscalYear > 2020)
      return fiscalYear

    return undefined
  }

  setSelectedFiscalYear(fiscalYear: number): void {
    this.selectedFiscalYear = fiscalYear
    this.setSelectedFiscalYearCookie(fiscalYear)
  }

  setSelectedFiscalYearCookie(fiscalYear: number): void {
    const expires = new Date() // Now
    expires.setUTCFullYear(expires.getUTCFullYear() + 1) // Add one year

    const cookies = new Cookies()
    cookies.set('fiscalYear', fiscalYear,
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict',
        expires: expires
      })
  }
  
  getPaginationFromCookieAndSetDefaultIfNone(): IPaginationSettings {
    const pagination = new Cookies().get<IPaginationSettings>('paginationSettings')

    if (pagination)
      return pagination

    const defaultVal: IPaginationSettings = {
      sortBy: 'updatedAt',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      filter: '',
      yearFilter: undefined,
      statusFilter: '-1',
      assignedToFilter: undefined,
      progressFilter: ''
    }

    this.setPaginationSettingsCookie(defaultVal)
    return defaultVal
  }

  setPagination(paginationSettings: IPaginationSettings): void {
    this.paginationSettings = paginationSettings
    this.setPaginationSettingsCookie(paginationSettings)
  }

  setPaginationSettingsCookie(pagination: IPaginationSettings): void {
    const expires = new Date() // Now
    expires.setUTCFullYear(expires.getUTCFullYear() + 1) // Add one year

    const cookies = new Cookies()
    cookies.set('paginationSettings', pagination,
      {
        path: '/',
        domain: `.${this.cookieDomain}`,
        httpOnly: false,
        secure: this.secureCookie,
        sameSite: 'strict',
        expires: expires
      })
  }

  async setTaxYear(): Promise<boolean> {
    try {
      const response = await axios.get<number>(this.serviceRoot + '/current-tax-year')
      this.fiscalYear = response.data
      
      return true
    } catch (e) {
      return false
    }
  }
}
