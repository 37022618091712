import {Message} from "@/types/Message";

export abstract class StateBase {
  apiRoot: string
  serviceRoot: string
  messages: Message[]

  protected constructor(messages: Message[], serviceBasePath: string) {
    this.apiRoot = process.env.VUE_APP_API_ROOT
    this.serviceRoot = this.apiRoot + serviceBasePath
    this.messages = messages
  }
  
  abstract logOut(): void
}
