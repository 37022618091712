import axios, {AxiosError, AxiosResponse} from 'axios'
import {Message} from '@/types/Message'
import {IServerError} from '@/types/axios/IServerError'
import {IApiResponse} from '@/types/responses/IApiResponse'
import {IErrorData} from '@/types/axios/IErrorData'

// eslint-disable-next-line
export function useAxiosUtils() {
  function responseToMessage(response: AxiosResponse<IApiResponse>): Message {
    return new Message(Message.SUCCESS, response.data.message)
  }
  
  function getErrorCode(error: unknown): number {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<IServerError>
      
      if (axiosError.response)
        return axiosError.response.status
    }
    
    return -1
  }
  
  function errorToErrorData(error: unknown): IErrorData {
    /* eslint-disable */
    return (error as any).response.data.status !== undefined
      ? (error as any).response.data as IErrorData
      : (error as any).response as IErrorData
    /* eslint-disable */
  }

  function errorToMessage(error: unknown): Message {
    const errorData = errorToErrorData(error)
    
    if (errorData && errorData.status === 404) {
      return new Message(Message.ERROR, '404 - Not found')
    }

    if (errorData && errorData.status === 413) {
      return new Message(Message.ERROR, '413 - Entity too large')
    }
    
    if (errorData && errorData.errors) {
      const map = new Map<string, string[]>(
        Object.entries(errorData.errors)
      )
      
      const errorMessages: string[] = []
      map.forEach((value) => {
        errorMessages.push(value.join('\n'))
      })
      
      return new Message(Message.ERROR, errorMessages.join('\n'))
    }

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<IServerError>
      let message: string

      if (axiosError.response && axiosError.response.data.detail && axiosError.response.data.detail.length > 0)
        message = axiosError.response.data.detail
      else if (axiosError.response && axiosError.response.data && axiosError.response.data.toString().length > 0 &&
        axiosError.response.data.toString().indexOf("Exception") === -1)
        message = axiosError.response.data.toString()
      else
        message = axiosError.message
      
      return new Message(Message.ERROR, message)
    }

    return new Message(Message.ERROR, error as string)
  }

  return {
    responseToMessage,
    getErrorCode,
    errorToErrorData,
    errorToMessage
  }
}