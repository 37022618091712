
import {computed, defineComponent, watch} from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import {useStore} from '@/store/store'

export default defineComponent({
  name: 'App',

  setup() {
    const $q = useQuasar()
    const store = useStore()

    const currentLang = computed<string>(() => store.applicationState.currentLang)
    watch(currentLang, val => {
      setLanguage(val)
    })

    function setLanguage(lang: string) {
      import('quasar/lang/' + (lang.toLowerCase() === 'en' ? 'en-US' : 'fr'))
          .then(lang => {
            $q.lang.set(lang.default)
          })
    }
  }
})
