import {IEntityDto} from '@/types/dtos/IEntityDto'
import {ISectionDto} from '@/types/dtos/form/ISectionDto'

export interface IFormDto extends IEntityDto {
  fiscalYear: number
  province: string
  createdAt: number
  updatedAt: number | undefined
  sections: ISectionDto[]
}

export const EmptyForm: IFormDto = {
  id: '',
  fiscalYear: -1,
  province: '',
  createdAt: -2000000000,
  updatedAt: undefined,
  sections: []
}
