import {EntityStateBase} from '@/store/types/EntityStateBase'
import {IPlanDto} from '@/types/dtos/IPlanDto'
import {Message} from '@/types/Message'

export class PlansState extends EntityStateBase<IPlanDto> {
  constructor(messages: Message[]) {
    super(messages, 'plans')
  }
  
  postFetch(): Promise<boolean> {
    return Promise.resolve(false)
  }
}